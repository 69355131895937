import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['viewport', 'container', 'prevButton', 'nextButton'];

  declare readonly viewportTarget: HTMLElement;
  declare readonly hasViewportTarget: boolean;
  declare readonly containerTarget: HTMLElement;
  declare readonly prevButtonTarget?: HTMLButtonElement;
  declare readonly hasPrevButtonTarget: boolean;
  declare readonly nextButtonTarget?: HTMLButtonElement;
  declare readonly hasNextButtonTarget: boolean;

  private resizeObserver!: ResizeObserver;

  private onScroll = () => this.updateButtonStates();

  connect(): void {
    if (!this.hasViewportTarget) return;
    this.updateButtonStates();
    this.containerTarget.addEventListener('scroll', this.onScroll);

    this.resizeObserver = new ResizeObserver(() => {
      this.updateButtonStates();
    });
    this.resizeObserver.observe(this.containerTarget);
  }

  disconnect(): void {
    this.containerTarget.removeEventListener('scroll', this.onScroll);

    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.containerTarget);
      this.resizeObserver.disconnect();
    }
  }

  next() {
    const targetPosition = this.getNextScrollPosition();
    if (targetPosition !== null) {
      this.containerTarget.scrollTo({ left: targetPosition, behavior: 'smooth' });
    }
  }

  prev() {
    const targetPosition = this.getPrevScrollPosition();
    if (targetPosition !== null) {
      this.containerTarget.scrollTo({ left: targetPosition, behavior: 'smooth' });
    }
  }

  private getSlidesPositions(): number[] {
    const slides = Array.from(this.containerTarget.children) as HTMLElement[];
    const containerRect = this.containerTarget.getBoundingClientRect();
    return slides.map(slide => {
      const slideRect = slide.getBoundingClientRect();
      return slideRect.left - containerRect.left + this.containerTarget.scrollLeft;
    });
  }

  private getNextScrollPosition(): number | null {
    const positions = this.getSlidesPositions();
    const currentScrollLeft = this.containerTarget.scrollLeft;
    for (let position of positions) {
      if (position - 1 > currentScrollLeft) {
        return position;
      }
    }
    return null;
  }

  private getPrevScrollPosition(): number | null {
    const positions = this.getSlidesPositions();
    const currentScrollLeft = this.containerTarget.scrollLeft;
    for (let i = positions.length - 1; i >= 0; i--) {
      if (positions[i] + 1 < currentScrollLeft) {
        return positions[i];
      }
    }
    return 0;
  }

  private updateButtonStates = () => {
    const { scrollLeft, scrollWidth, clientWidth } = this.containerTarget;
    const maxScrollLeft = scrollWidth - clientWidth;

    const firstSlide = this.containerTarget.firstElementChild as HTMLElement;
    const firstSlidePadding = parseInt(getComputedStyle(firstSlide).paddingLeft) || 0;

    const threshold = 5;

    if (this.hasPrevButtonTarget) {
      this.prevButtonTarget!.disabled = scrollLeft <= firstSlidePadding + threshold;
    }
    if (this.hasNextButtonTarget) {
      this.nextButtonTarget!.disabled = maxScrollLeft - scrollLeft <= threshold;
    }
  };
}
